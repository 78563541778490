<template>
  <div class="about">
    <div class="row justify-content-start">
      <div class="col-12 bg">
        <div style="margin-top: 20px;">
          <p class="title">隐形保镖隐形车衣报价系统</p>
          <p class="describe">请选择汽车品牌，获取产品报价信息</p>
        </div>
        <div class="row justify-content-start" style="margin: 20px 0px 0px 0px;">
          <div class="col-6">
            <select class="form-control" v-model="brand" @change="onChangeBrand">
              <option
                v-for="item in brandList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              ></option>
            </select>
            <select class="form-control" style="margin-top:4px;" v-model="type" @change="onChannelChange">
              <option v-for="item in typeList" :key="item" :label="item" :value="item"></option>
            </select>
          </div>
          <div class="col-6">
            <select class="form-control" v-model="model.id">
              <option
                v-for="item in currentModelList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></option>
            </select>
            <select class="form-control" style="margin-top:4px;" v-model="level">
              <option
                v-for="item in levelList"
                :key="item"
                :label="'隐形保镖 '+item+'系列'"
                :value="item"
              ></option>
            </select>
          </div>
        </div>
        <button class="button" style="margin: 20px 0px 0px 0px; width:200px;" @click="onChange">提交查询</button>
      </div>
    </div>
    <div class="row" style="padding: 30px 0px 30px 0px; background-color:#f3f3f2">
      <div class="col-1"></div>
      <div class="col-10 price">
        <header class="p-header">
          <h3 class="title">隐形保镖隐形车衣报价查询结果</h3>
          <p style="font-size: 15px; color: #ffffff; padding-top: 5px;">CAR YBOP PRICE</p>
        </header>
        <table class="table table-bordered table-hover" style="margin: 10px 4px 10px 2px;font-size:13px;">
          <thead>
            <tr>
              <th scope="col">部位</th>
              <th scope="col">报价</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>车顶盖</td>
              <td>¥{{price.price1}}</td>
            </tr>
            <tr>
              <td>引擎盖</td>
              <td>¥{{price.price2}}</td>
            </tr>
            <tr>
              <td>前保险杠</td>
              <td>¥{{price.price3}}</td>
            </tr>
            <tr>
              <td>后保险杠</td>
              <td>¥{{price.price4}}</td>
            </tr>
            <tr>
              <td>双前叶子板</td>
              <td>¥{{price.price5}}</td>
            </tr>
            <tr>
              <td>双后叶子板</td>
              <td>¥{{price.price6}}</td>
            </tr>
            <tr>
              <td>左门组合</td>
              <td>¥{{price.price7}}</td>
            </tr>
            <tr>
              <td>右门组合</td>
              <td>¥{{price.price8}}</td>
            </tr>
            <tr>
              <td>左侧裙</td>
              <td>¥{{price.price9}}</td>
            </tr>
            <tr>
              <td>右侧裙</td>
              <td>¥{{price.price10}}</td>
            </tr>
            <tr>
              <td>双后视镜</td>
              <td>¥{{price.price11}}</td>
            </tr>
            <tr>
              <td>后备箱盖</td>
              <td>¥{{price.price12}}</td>
            </tr>
            <tr style="color: #ecbd4d; font-size: 16px; font-weight: bold;">
              <td>整车</td>
              <td>¥{{price.price0}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-1"></div>
    </div>
  </div>
</template>
<script>
// import { onMounted } from 'vue'
import axios from 'axios'
export default {
  components: {},
  data() {
    return {
      brandList: [],
      modelList: [],
      currentModelList: [],
      levelList: [],
      typeList: ["集团PDI渠道", "零售渠道"],
      priceList: [],
      dataList: [
        {
          name: "集团PDI渠道",
          list: [
            "PU亮黑",
            "PU哑光",
            "D65",
            "D75",
            "P70",
            "P80",
            "P90",
            "P100",
            "PDI亮黑",
            "PDI哑光",
          ],
        },
        {
          name: "零售渠道",
          list: ["LUX", "TOP", "TOP WSH", "TOP Matte", "PRO", "MAX", "PRO MAX"],
        },
      ],
      brand: null,
      type: null,
      level: null,
      model: {
        id: null,
        model: null,
        image: null
      },
      price: {
        price0: null,
        price1: null,
        price2: null,
        price3: null,
        price4: null,
        price5: null,
        price6: null,
        price7: null,
        price8: null,
        price9: null,
        price10: null,
        price11: null,
        price12: null
      },
      modelInfo: {}
    }
  },
  created() {
    this.init()
  },
  methods: {
    getAllBrand() {
      return new Promise((resolve, reject) => {
        axios
          .get('/e-car/chinaybop-brand/getAll')
          // .get('http://localhost:8080/server/chinaybop-brand/getAll')
          .then(res => {
            this.brandList = res.data
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getAllModel() {
      return new Promise((resolve, reject) => {
        axios
          .get('/e-car/chinaybop-model/getAll')
          // .get('http://localhost:8080/server/chinaybop-model/getAll')
          .then(res => {
            this.modelList = res.data
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getAllPrice() {
      return new Promise((resolve, reject) => {
        axios
          .get('/e-car/chinaybop-price/getAll')
          // .get('http://localhost:8080/server/chinaybop-price/getAll')
          .then(res => {
            this.priceList = res.data
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async init() {
      await this.getAllBrand()
        .then(() => {
          this.brand = this.brandList[0].name
        })
        .catch()
      await this.getAllModel()
        .then(() => {
          this.currentModelList = this.modelList.filter(
            e => String(e.brandName) === String(this.brand)
          )
          this.model.id = this.currentModelList[0].id;
          this.model.model = this.currentModelList[0].type;
          this.model.image = this.currentModelList[0].image;
          this.levelList = this.dataList[0].list;
          this.level = this.levelList[0];
          this.type = this.typeList[0];
        })
        .catch()
      await this.getAllPrice()
        .then(() => {
          this.price = this.priceList.find(
            e =>
              String(e.model) === String(this.model.model) &&
              String(e.level) === String(this.level) &&
              String(e.type) === String(this.type)
          )
        })
        .catch()
      console.log(this.brandList)
      console.log(this.modelList)
      console.log(this.priceList)
    },
    onChangeBrand() {
      this.currentModelList = this.modelList.filter(
        e => String(e.brandName) === String(this.brand)
      )
      if (this.currentModelList.length !== 0) {
        this.model.id = this.currentModelList[0].id
        this.model.model = this.currentModelList[0].type
        this.model.image = this.currentModelList[0].image
      } else {
        this.model.id = null
        this.model.model = null
        this.model.image = null
      }
    },
    onChange() {
      this.model.model = this.currentModelList.find(
        e => String(e.id) === String(this.model.id)
      ).type
      this.model.image = this.currentModelList.find(
        e => String(e.id) === String(this.model.id)
      ).image
      this.price = this.priceList.find(
        e =>
          String(e.model) === String(this.model.model) &&
          String(e.level) === String(this.level) &&
          String(e.type) === String(this.type)
      )
    },
    onChannelChange() {
      this.levelList = this.dataList.find((e) => e.name == this.type).list;
      this.level = this.levelList[0];
    },
  }
}
</script>
<style scoped>
.bg {
  background-image: url('https://car-1301197907.cos.ap-beijing.myqcloud.com/price-1.jpg');
  height: 300px;
}
.title {
  font-size: 18px;
  color: #ffffff;
}
.describe {
  font-size: 12px;
  color: #ffffff;
}
.button {
  background-color: #ecbd4d;
  border: 1px solid #ecbd4d;
  border-radius: 4px;
  padding: 10px 15px 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
}

.button:hover {
  background-color: #ecbd4d;
}

.button:active {
  background-color: #ecbd4d;
}

.price {
  min-height: 400px;
  background-color: #ffffff;
  padding: 0;
}

.p-header {
  border-radius: 5px;
  background-color: #ecbd4d;
  height: 100px;
}

.p-header .title {
  color: #ffffff;
  padding: 30px 0 0 0;
}
</style>